import React, { useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const theme = {
  colors: {
    background: '#fff',
    text: '#2C3E50',
    primary: '#3498DB',
    secondary: '#456F6F',
    surface: '#fff',
    accent: '#456F6F',
    textSecondary: '#7F8C8D',
  },
  fonts: {
    main: 'VT323, monospace',
  },
};

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'VT323', monospace;
  }
`;

const Container = styled.div`
  font-family: ${(props) => props.theme.fonts.main};
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  min-height: 100vh;
`;

const Banner = styled.div`
  width: 100%;
  background-image: url('assets/banner.png');
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 40px 0;
  text-align: center;
  font-family: ${props => props.theme.fonts.main};
  font-size: 24px;
  letter-spacing: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4); /* Adjust this value for opacity */
  }

  /* Make sure the text stays on top of the overlay */
  > * {
    position: relative;
    z-index: 1;
  }
`;

const BottomBanner = styled(Banner)`
  margin-top: 20px;
  transform: rotate(180deg);
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
`;

const LogoWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 24px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  position: relative;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;


const Title = styled.h1`
  font-family: ${props => props.theme.fonts.main};
  font-size: 42px;
  letter-spacing: 2px;
  margin: 20px 0;
  color: ${props => props.theme.colors.text};
  text-align: center;
`;

const MainScreenshot = styled.img`
  width: 100%;
  max-width: 300px;
  border-radius: 12px;
  margin: 20px auto;
  display: block;
`;

const GallerySection = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  border: 2px solid ${props => props.theme.colors.primary}22;
  border-radius: 12px;
  background: ${props => props.theme.colors.primary}05;
`;

const GalleryTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 24px;
  color: ${props => props.theme.colors.text};
  text-align: center;
  margin-bottom: 20px;
`;

const ScreenshotGallery = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 40px;
  padding: 20px 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ScreenshotWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  width: 200px;
  scroll-snap-align: start;
`;

const Screenshot = styled.img`
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const PromoText = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin: 10px auto;
  color: ${props => props.theme.colors.textSecondary};
  text-align: center;
  max-width: 400px;
`;

const AppStoreBadge = styled.img`
  height: 40px;
  margin: 20px auto;
  display: block;
  cursor: pointer;
`;

const ContactSection = styled.div`
  text-align: center;
  padding: 40px 20px;
  margin: 20px auto;
  max-width: 600px;
`;

const ContactText = styled.p`
  font-size: 20px;
  color: ${props => props.theme.colors.text};
  margin-bottom: 10px;
`;

const ContactLink = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const FAQSection = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  border: 2px solid ${props => props.theme.colors.primary}22;
  border-radius: 12px;
  background: ${props => props.theme.colors.primary}05;
`;

const FAQTitle = styled.h2`
  font-family: ${props => props.theme.fonts.main};
  font-size: 24px;
  color: ${props => props.theme.colors.text};
  text-align: center;
  margin-bottom: 20px;
`;

const FAQItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  padding: 15px;
  border-radius: 8px;
  background: ${props => props.theme.colors.surface};
  transition: all 0.3s ease;
  
  &:hover {
    background: ${props => props.theme.colors.primary}10;
  }
`;

const FAQQuestion = styled.div`
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  margin-bottom: ${props => props.isOpen ? '10px' : '0'};
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transform: rotate(${props => props.isOpen ? '180deg' : '0deg'});
    transition: transform 0.3s ease;
  }
`;

const FAQAnswer = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.textSecondary};
  line-height: 1.5;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  margin: 0;
`;

const Footer = styled.div`
  padding: 20px;
  margin-top: 40px;
  background: ${props => props.theme.colors.primary}05;
`;

const FooterContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const FooterLink = styled.div`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  font-size: 16px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ExpandableContent = styled.div`
  max-height: ${props => props.isOpen ? '2000px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  background: ${props => props.theme.colors.surface};
  border-radius: 8px;
  margin-top: ${props => props.isOpen ? '20px' : '0'};
  padding: ${props => props.isOpen ? '20px' : '0'};
  font-size: 14px;
  line-height: 1.6;
  color: ${props => props.theme.colors.textSecondary};
`;

const WebVersionLink = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  display: block;
  text-align: center;
  
  &:hover {
    text-decoration: underline;
  }
`;

const App = () => {
  const [openFAQIndex, setOpenFAQIndex] = useState(null);
  const [showTerms, setShowTerms] = useState(false);
  const [showImpressum, setShowImpressum] = useState(false);

  const handleFAQClick = (index) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  const screenshots = [
    {
      image: 'assets/screenshots/2.png',
      text: 'Welcome to the Querfeldein App! Get a quick overview of news, your team, the overall standings.'
    },
    {
      image: 'assets/screenshots/3.png',
      text: 'Look for upcoming cyclocross events. Look at game results and statistics.'
    },
    {
      image: 'assets/screenshots/7.png',
      text: 'Select 4 riders for your team to compete against other players.'
    },
    {
      image: 'assets/screenshots/4.png',
      text: 'Check overall standings and your team\'s progress...'
    },
    {
      image: 'assets/screenshots/9.png',
      text: 'or join public and private leagues and be part of a growing community.'
    },
    {
      image: 'assets/screenshots/5.png',
      text: 'Manage your profile and settings.'
    },
    {
      image: 'assets/screenshots/6.png',
      text: 'Use the dark mode.'
    }
  ];

  const faqItems = [
    {
      question: "How do I get started with selecting riders?",
      answer: "You can select 4 riders for your team with a total budget of 20 Credits. Each rider has a different cost based on their performance potential. Strategic tip: Look at riders' past performances and current form to maximize your point-earning potential. Consider mixing high-cost favorites with promising talents to balance your team.",
    },
    {
      question: "What types of leagues can I join and how do they work?",
      answer: "Besides the game-wide overall standings, there are two types of leagues: public and private. Public leagues are open to all players, while private leagues can be created and shared with friends via invitation codes. You can start a new league at any point during the season, making it perfect for friendly competitions. Each league has its own standings and you can participate in multiple leagues simultaneously.",
    },
    {
      question: "How does the scoring and ranking system work?",
      answer: "Your ranking is determined by the total points earned by your selected riders in real cyclocross events. Points are awarded based on riders' finishing positions in actual races. The better your riders perform, the higher you'll climb in the rankings. You can track your progress and compare your performance with other players in your leagues.",
    },
    {
      question: "How can I connect with other players?",
      answer: "The My Friends feature allows you to connect with other players and track their performance across all leagues. You can add friends by searching for their username, and once connected, you'll be able to see their standings and achievements. It's a great way to add some friendly competition to your experience.",
    },
    {
      question: "Where can I find detailed statistics and additional features?",
      answer: "Your profile page contains basic statistics and achievements, but for more detailed analytics and features, visit querfeldein.cc. There you'll find comprehensive statistics, historical data, and additional tools to enhance your fantasy cyclocross experience. Regular updates add new features and improvements to both the app and website.",
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Banner>
          Join the Querfeldein!
        </Banner>
        
        <ContentGrid>
          <LeftContainer>
            <MainScreenshot
              src="assets/phoneview.png"
              alt="Querfeldein Main Screenshot"
            />
          </LeftContainer>

          <RightContainer>
            <LogoWrapper>
              <Logo src="assets/icon.png" alt="Querfeldein Logo" />
            </LogoWrapper>
            <Title>Welcome to the Querfeldein</Title>
            <PromoText>
              This is the Querfeldein - a simple Cyclocross Fantasy League. Select your four favorite cyclocross riders and compete against other participants. Free and on iOS. 
            </PromoText>
            <AppStoreBadge 
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us"
              alt="Download on the App Store"
              onClick={() => window.open('YOUR_APP_STORE_LINK', '_blank')}
            />
            <WebVersionLink 
              href="http://querfeldein.cc" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Or try the web version
            </WebVersionLink>
          </RightContainer>
        </ContentGrid>

        <GallerySection>
          <GalleryTitle>Explore Features</GalleryTitle>
          <ScreenshotGallery>
            {screenshots.map((item, index) => (
              <ScreenshotWrapper key={index}>
                <Screenshot
                  src={item.image}
                  alt={`Querfeldein Screenshot ${index + 1}`}
                />
                <PromoText>
                  {item.text}
                </PromoText>
              </ScreenshotWrapper>
            ))}
          </ScreenshotGallery>
        </GallerySection>

        <FAQSection>
          <FAQTitle>Frequently Asked Questions</FAQTitle>
          {faqItems.map((item, index) => (
            <FAQItem key={index} onClick={() => handleFAQClick(index)}>
              <FAQQuestion isOpen={openFAQIndex === index}>
                {item.question}
                <FontAwesomeIcon icon={faChevronDown} />
              </FAQQuestion>
              <FAQAnswer isOpen={openFAQIndex === index}>
                {item.answer}
              </FAQAnswer>
            </FAQItem>
          ))}
        </FAQSection>

        <ContactSection>
          <ContactText>Questions? Get in touch with us:</ContactText>
          <ContactLink href="mailto:mail@querfeldein.cc">
            <FontAwesomeIcon icon={faEnvelope} />
            mail@querfeldein.cc
          </ContactLink>
        </ContactSection>

        <Footer>
          <FooterContent>
            <FooterLinks>
              <FooterLink onClick={() => setShowTerms(!showTerms)}>
                Terms of Service {showTerms ? '▼' : '▶'}
              </FooterLink>
              <FooterLink onClick={() => setShowImpressum(!showImpressum)}>
                Impressum {showImpressum ? '▼' : '▶'}
              </FooterLink>
            </FooterLinks>
            
            <ExpandableContent isOpen={showTerms}>
              <h3>Terms of Use</h3>
              <p>Welcome to Querfeldein, the Cyclocross Fantasy League. By using our service, you agree to these terms:</p>
              
              <h4>Independent Service</h4>
              <p>Querfeldein is an independent fantasy sports service and is not affiliated with, endorsed by, or connected to any official cycling organizations, governing bodies, teams, or commercial entities within the cycling industry. Any references to actual cycling events, athletes, or results are used for fantasy gaming purposes only.</p>
              
              <h4>Acceptance of Terms</h4>
              <p>By accessing or using Querfeldein, you agree to be bound by these Terms of Use and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.</p>
              
              <h4>Use of Service</h4>
              <p>You agree to use Querfeldein for lawful purposes only and in a way that does not infringe upon the rights of others or inhibit their use and enjoyment of the service.</p>
              
              <h4>User Accounts</h4>
              <p>You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account. You may create an account using our sign-up process or by signing in with Apple or Google services.</p>
              
              <h4>Fantasy League Rules</h4>
              <ul>
                <li>You must select 4 riders with a total cost of 20 Credits.</li>
                <li>Rider selections will be locked one hour before each race.</li>
                <li>You may change your team at any time before the lock period.</li>
                <li>Rider points are calculated based on their performance in the races and are updated after each race.</li>
              </ul>
              
              <h4>Content</h4>
              <p>You are solely responsible for any content you post on Querfeldein, including your profile information or the naming of leagues. You agree not to post content that is unlawful, offensive, defamatory, or violates the privacy or intellectual property rights of others.</p>
              
              <h4>Intellectual Property</h4>
              <p>The content, organization, graphics, design, and other matters related to Querfeldein are protected under applicable copyrights and other proprietary laws. Copying, redistribution, use, or publication of any such matters or any part of the site is prohibited without our express permission.</p>
              
              <h4>User Interactions</h4>
              <p>Querfeldein allows users to interact through features like "Hey" and "Heart" buttons. You agree to use these features responsibly and not to harass or spam other users.</p>
              
              <h4>Disclaimer of Warranties</h4>
              <p>Querfeldein is provided "as is" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose.</p>
              
              <h4>Limitation of Liability</h4>
              <p>Querfeldein shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the service.</p>
              
              <h4>Privacy Policy and Data Rights</h4>
              <p>Your use of Querfeldein is also governed by our Privacy Policy, which is incorporated into these Terms of Use by reference. We take your privacy seriously. We only store your username and your password (which is encrypted). We do not collect any other personal data, and we do not share any user data with third parties. For users who sign up using Apple OAuth, we only store the username associated with your account.</p>
              
              <p>In accordance with applicable data protection laws, users maintain complete control over their personal data. Users have the right to:</p>
              <ul>
                <li>Access and view all their stored data at any time</li>
                <li>Request modifications to their stored information</li>
                <li>Request complete deletion of their account and associated data</li>
                <li>Export their data in a commonly used format</li>
              </ul>
              
              <p>The aforementioned data consists exclusively of: username, encrypted password, and in-game selections/activities. These rights can be exercised at any time through the application's user interface or by contacting our support.</p>
              
              <h4>Changes to Terms</h4>
              <p>We reserve the right to modify these Terms of Use at any time. We will notify users of any significant changes via email or through a prominent notice on our site.</p>
              
              <h4>Governing Law</h4>
              <p>These Terms of Use shall be governed by and construed in accordance with the applicable laws, without regard to its conflict of law provisions.</p>
              
              <p>By using Querfeldein, you acknowledge that you have read and understood these Terms of Use and agree to be bound by them. If you have any questions about these Terms, please contact us.</p>
            </ExpandableContent>
            
            <ExpandableContent isOpen={showImpressum}>
              <h3>Impressum</h3>
              <p>Moritz Hacke
              Klingenteichstraße 1/1
              69117 Heidelberg
              mail@querfeldein.cc</p>
            </ExpandableContent>
          </FooterContent>
        </Footer>

        <BottomBanner>
          Explore More with Querfeldein!
        </BottomBanner>
      </Container>
    </ThemeProvider>
  );
};

export default App;
